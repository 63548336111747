module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'LoanFuze - Making Refinancing Easier', // Navigation and Site Title
  titleAlt: 'LoanFuze - Making Refinancing Easier', // Title for JSONLD
  description: 'Our tools provide a clear way of choosing how to refinance your loans',
  headline: 'Our tools provide a clear way of choosing how to refinance your loans', // Headline for schema.org JSONLD
  url: 'https://loanfuze.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/img/logo.svg', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Prismic', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Rick', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: '@loan_fuze', // Twitter Username
  facebook: 'loanfuze', // Facebook Site Name
  googleAnalyticsID: 'UA-149907083-1',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
